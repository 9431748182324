/* overwrite colors for side-panel tabs */

.editor-container .polotno-side-tabs-container {
  background-image: url('https://d19ep7nqjvgj4d.cloudfront.net/adgen-fe/editor_sidebar_bg.png') !important;
  background: #ebe9fe;
  width: 100px;
  background-size: cover;
  font-size: '12px' !important;
  font-weight: 200;
  padding-top: 20px;
}

.editor-container .polotno-side-tabs-container .polotno-side-panel-tab:hover,
.editor-container .polotno-side-tabs-container .polotno-side-panel-tab.active {
  display: flex;
  flex-direction: column;
  background: rgb(140, 102, 244);
  background: linear-gradient(90deg, rgba(140, 102, 244, 1) 0%, rgba(124, 58, 187, 1) 100%);
  transition: background 0.6s ease-in, color 0.5s ease-in;
  border-left: #ffffff 2px solid;
  width: 100px;
}

.polotno-side-panel-tab .span {
  color: 'black';
  font-size: 17px !important;
}

#root
  > div
  > div:nth-child(2)
  > div
  > div
  > div.go1236784588
  > div
  > div.go1430256704.polotno-side-tabs-container
  > div
  > div
  > div
  > span {
  font-size: 17px !important;
  font-weight: 400;
}

.editor-container .polotno-side-tabs-container .polotno-side-panel-tab {
  color: white;
  transition: background 0.4s ease-out, color 0.4s ease-out;
  margin: 10px 0 10px;
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  fill: white !important;
}

/* change hover style of all buttons */
.editor-container .bp5-button:not([class*='editor-container-intent-']):hover {
  background-color: rgba(106, 57, 239, 0.5);
}

.polotno-panel-container {
  background: #ebe9fe;
  border: 0px;
  border-radius: 0px 20px 20px 0px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  .bp5-navbar {
    box-shadow: 0 0 0 1px rgba(17, 20, 24, 0), 0 1px 1px rgba(17, 20, 24, 0) !important;
  }
}

.right-side-bar {
  position: relative;
  right: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(90deg, rgba(106, 57, 239, 1) 0%, rgba(119, 52, 189, 1) 100%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.bp5-icon-undo {
  color: #7a5af8 !important;
}

.bp5-icon-more {
  color: #7a5af8 !important;
}

.go3456988929 {
  padding: 20px;
  background: transparent;
  border-radius: 20px 20px 20px 20px !important;

  .bp5-navbar {
    border-radius: 20px 20px 0 0 !important;
    border-bottom: 1px solid #eceef2 !important;
    box-shadow: 0 0 0 1px rgba(17, 20, 24, 0), 0 1px 1px rgba(17, 20, 24, 0) !important;
  }
}

.bp5-button.bp5-minimal {
  background: none; /* Remove any default background */
  border: none; /* Remove any default border */
  box-shadow: none; /* Remove any default shadow */
}

.bp5-icon.bp5-icon-time {
  margin-right: 8px; /* Space between icon and text */
}

.bp5-button-text {
  color: #5c7080; /* Color for the text, adjust as needed */
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
}

/* overwrite styles for nav-bar tabs */


/* Loader */

.phone-container {
  display: flex;
  padding: .5rem;
  align-items: center;
    justify-content: center;
    height: 100vh;
   
}
.iphone-case {
  --border-radius-outer: 2rem;
  --outer-padding: 0.5rem;
  --case-color: hsl(0, 0%, 0%);
  --border-radius-inner: calc( var(--border-radius-outer) - var(--outer-padding));
  box-shadow: 0px 0px 0px 2px white;
  background-color: #f7f7f7;
  border-radius: var(--border-radius-outer);
  padding: var(--outer-padding);
  width: min(350px);
  aspect-ratio: 1/2;
  position: relative;
  margin-inline: auto;
  border: 2px solid #835fe7;
}
.iphone-case > .child-container {
  /* --start-color: hsl(0, 0%, 0%);
  --shadow-x: 2px;
  height: 50%;
  border-radius: 50%;
  background-color: var(--base-color, #fdc1f7);
  box-shadow: 0px 2px 10px var(--base-color);
  background-size: 100% 200%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: auto; */
  height: 60%;
}
.iphone-case .gradient-1 {
  --gradient-stop-1: 30%;
  border-top-left-radius: var(--border-radius-inner);
  border-top-right-radius: var(--border-radius-inner);
  border-bottom: 3px solid var(--base-color);
  box-shadow: 0px 2px 4px var(--base-color);
  /* background-image: radial-gradient(circle at bottom, var(--start-color) var(--gradient-stop-1), var(--color-stop-center),  var(--base-color) 90%); */
   background-image: -webkit-radial-gradient(circle, var(--start-color) var(--gradient-stop-1), var(--color-stop-center),  var(--base-color) 90%);
   background-image: radial-gradient(circle, var(--start-color) var(--gradient-stop-1), var(--color-stop-center),  var(--base-color) 90%);
   background-position: 50% 200%;
  background-size: 100% 200%;
}
.iphone-case .gradient-2 {
  --gradient-stop-1: 65%;
  --gradient-stop-2: 80%;
  border-bottom-left-radius: var(--border-radius-inner);
  border-bottom-right-radius: var(--border-radius-inner);
  border-top: 3px solid var(--base-color);
  box-shadow: 0px -2px 4px var(--base-color);
  /* background-image: radial-gradient(circle at top, var(--start-color) var(--gradient-stop-1), var(--color-2, hsl(0, 0%, 0%)) var( --gradient-stop-2) , var(--base-color) 100%); */
    background-image: -webkit-radial-gradient(circle, var(--start-color) var(--gradient-stop-1), var(--color-2, hsl(0, 0%, 0%)) var( --gradient-stop-2) , var(--base-color) 100%);
  background-image: radial-gradient(circle, var(--start-color) var(--gradient-stop-1), var(--color-2, hsl(0, 0%, 0%)) var( --gradient-stop-2) , var(--base-color) 100%);
  background-position: 50% 100%;
}
.case-1 .gradient-1 {
  --base-color: #fdc1f7;
  --color-stop-center: #230a77, #c84fe4;
}
.case-1 .gradient-2 {
  --color-2: #400898;;
  --base-color: #8778d1;
}
  
.iphone-case:before {
  content: '';
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.3rem;
  background-color: #835fe7;
  width: 22%;
  border-radius: 25px;
}

.progress-bar_{
  display: flex;
  height: 200px;
  align-items: end;
  direction: "coloumn";
  justify-content: center;
}
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;