.icon-hover-effect {
  transition: transform 0.3s ease;
}

.icon-hover-effect:hover {
  transform: scale(1.2); /* Example effect: Scale up the icon */
  color: #1890ff; /* Change color on hover */
}

.hoverable-text-box {
  border: 1px solid #b3b3b3; /* Darker grey border */
  padding: 5px;
  border-radius: 7px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease; /* Smooth transition for properties */
  text-align: left;
  overflow: hidden; /* Hide overflow */
  white-space: nowrap; /* Prevent wrapping to show ellipsis */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
  max-height: 40px; /* Initial max height to control box size */
  min-height: 30px;
  width: 100%; /* Adjust width as needed */
  margin-bottom: 5px;
}

// .hoverable-text-box:hover {
//   max-height: none; /* Allow box to expand to fit content */
//   white-space: normal; /* Allow text wrapping on hover */
// }

.ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  font-size: 10px !important;
}

.ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-title {
  font-size: 12px !important;
}

.ant-input-number-input-wrap .ant-input-number-input {
  max-height: 30px !important;
  font-size: 10px !important; /* Assuming you still want the font size adjustment */
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px; // Optional: adds a little space between tags
}

.ant-tag {
  margin-bottom: 4px; // Ensure there's a little space between wrapped lines
  font-size: 10px;
}

// .ant-drawer-header {
//   // padding: 8px 16px; // Adjust padding to reduce height, default might be larger
//   .ant-drawer-header-title {
//     .ant-drawer-title {
//       font-size: 14px; // Reduce font size of the title
//       // You can also adjust other properties like font-weight, line-height etc.
//     }
//   }
// }

// .ant-drawer-body {
//   width: '100%'; /* Adjust the padding as needed */
// }

@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;